import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Dialog from './Dialog.jsx'
import Separator from '../components/Separator.jsx'
import { TextField, FormControlLabel, Checkbox } from '@mui/material'
import redux from '../redux/index.js'
import { cleanFileName } from '../util.js'
import { isOffice } from '../lib/office/index.js'
import { BLUE, LIGHT_BLUE } from '../styles.js'

const SaveControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  padding-right: 10px;
  height: 42px;
`

const SaveButton = styled.div`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  width: 64px;
  height: 28px;

  background-color: ${({ disabled }) => disabled ? '#aaaaaa' : `${BLUE}`};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  font-size: 12px;
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  &:hover {
    color: ${({ disabled }) => disabled ? 'white' : `${LIGHT_BLUE}`};
  }
`

const CheckboxLabel = styled.div`
  font-family: Arial;
  font-size: 10px;
  margin-left: -5px;
`

const SaveDialog = ({
  // redux state
  strings,
  canShareInChat,
  // redux methods
  onHideSaveDialog,
  onSaveFile
}) => {
  // TODO file name should be picked from selection state
  // if a file was selected
  const [fileName, setFileName] = useState('')
  // TODO maybe remember default setting here?
  const [shareInChat, setShareInChat] = useState(false)

  const canSavePdf = isOffice()
  const [savePdf, setSavePdf] = useState(false)

  const [isSaving, setIsSaving] = useState(false)
  const saveDisabled = () => {
    return fileName.length === 0 || isSaving
  }

  const handleShareCheckbox = (event) => {
    setShareInChat(event.target.checked)
  }

  const handlePdfCheckbox = (event) => {
    setSavePdf(event.target.checked)
  }

  const handleSave = () => {
    setIsSaving(true)
    onSaveFile(
      fileName,
      canShareInChat ? shareInChat : false,
      canSavePdf ? savePdf : false
    )
  }

  return (
    <Dialog title={strings.save}>
      <Separator title='Filename' />
      <TextField
        variant='outlined'
        size='small'
        InputProps={{
          inputProps: {
            autoCapitalize: 'off',
            autoComplete: 'off',
            autoCorrect: 'off',
            spellCheck: 'false'
          }
        }}
        value={fileName}
        style={{
          background: 'white',
          width: 'calc(100% - 20px)',
          marginTop: 0,
          marginLeft: 10
        }}
        onChange={(e) => setFileName(cleanFileName(e.target.value))}
      />
      <SaveControls>
        {
          canShareInChat &&
            <FormControlLabel
              label={<CheckboxLabel>{strings.share_in_chat}</CheckboxLabel>}
              control={(
                <Checkbox
                  checked={shareInChat}
                  onChange={handleShareCheckbox}
                />
              )}
            />
        }
        {
          canSavePdf &&
            <FormControlLabel
              label={<CheckboxLabel>{strings.save_pdf}</CheckboxLabel>}
              control={(
                <Checkbox
                  checked={savePdf}
                  onChange={handlePdfCheckbox}
                />
              )}
            />
        }
        {(!canShareInChat || !canSavePdf) && <div />}
        <SaveButton
          disabled={saveDisabled()}
          onClick={() => !saveDisabled() && handleSave()}
        >
          {strings.save}
        </SaveButton>
      </SaveControls>
      <Separator title='Tags' />
    </Dialog>
  )
}

const mapStateToProps = (state) => {
  const { locale, teams } = state
  const { teamId, channelId, oneDrive } = teams.selectContext
  return {
    strings: locale.strings,
    canShareInChat: !!(teamId && channelId && !oneDrive)
  }
}

const mapDispatchToProps = (dispatch) => {
  const { dialog, teams } = redux.actions
  return {
    onHideSaveDialog: () => {
      dispatch(dialog.hideDialog())
    },
    onSaveFile: (fileName, shareInChat, savePdf) => {
      dispatch(
        teams.saveFile(fileName, shareInChat, savePdf)
      )
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveDialog)
