import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Dialog from './Dialog.jsx'
import UserDetails from './UserDetails.jsx'
import Separator from '../components/Separator.jsx'
import redux from '../redux/index.js'

const Text = styled.div`
  padding-left: 18px;
  padding-right: 18px;
  font-family: Arial;
  font-size: 11px;
  color: #2f2f2f;
`

const ListWrapper = styled.div`
  padding-left: 8px;
  padding-right: 8px;
`

const ChannelDetailsDialog = ({
  // redux state
  strings,
  teamId,
  channel,
  owners,
  members,
  userPhotos,
  // redux methods
  fetchChannelMembers,
  onHideDialog
}) => {
  useEffect(() => {
    fetchChannelMembers(teamId, channel.id)
  }, [teamId, channel.id])

  return (
    <Dialog title={strings.channel_details}>
      <Separator title={strings.channel_name} />
      <Text>{channel.displayName}</Text>
      <Separator title={strings.channel_description} />
      <Text>{channel.description}</Text>
      <Separator title={`${strings.owners} (${owners.length})`} />
      <ListWrapper>
        {
          owners.map(item => {
            return (
              <UserDetails
                key={item.userId}
                name={item.displayName}
                mail={item.email}
                photo={userPhotos[item.userId]}
              />
            )
          })
        }
      </ListWrapper>
      <Separator title={`${strings.members_and_guests} (${members.length})`} />
      <ListWrapper>
        {
          members.map(item => {
            return (
              <UserDetails
                key={item.userId}
                name={item.displayName}
                mail={item.email}
                photo={userPhotos[item.userId]}
              />
            )
          })
        }
      </ListWrapper>
    </Dialog>
  )
}

const mapStateToProps = (state) => {
  const { teamId, channelId } = state.dialog.meta
  const channels = state.teams.channels[teamId] || []
  const channel = channels.find(channel => channel.id === channelId)
  const channelMembers = state.teams.channelMembers[channelId] || []
  const owners = channelMembers.filter(member => {
    return member.roles.includes('owner') ? member : null
  }).filter(Boolean)
  const members = channelMembers.filter(member => {
    return member.roles.includes('owner') ? null : member
  }).filter(Boolean)
  return {
    strings: state.locale.strings,
    teamId,
    channel,
    owners,
    members,
    userPhotos: state.teams.userPhotos
  }
}

const mapDispatchToProps = (dispatch) => {
  const { teams, dialog } = redux.actions
  return {
    fetchTeamOwners: (teamId) => {
      dispatch(teams.fetchTeamOwners(teamId))
    },
    fetchTeamMembers: (teamId) => {
      dispatch(teams.fetchTeamMembers(teamId))
    },
    fetchChannelOwners: (teamId, channelId) => {
      dispatch(teams.fetchChannelOwners(teamId, channelId))
    },
    fetchChannelMembers: (teamId, channelId) => {
      dispatch(teams.fetchChannelMembers(teamId, channelId))
    },
    onHideDialog: () => {
      dispatch(dialog.hideDialog())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChannelDetailsDialog)
