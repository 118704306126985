import { SHOW_DIALOG_ACTION, HIDE_DIALOG_ACTION } from './actions'

const DEFAULT = {
  name: null,
  meta: null
}

/**
 * Reducer for dialogs
 */
export function dialog (state = DEFAULT, action) { // eslint-disable-line
  switch (action.type) {
    case SHOW_DIALOG_ACTION: {
      const { name, meta = null } = action
      return { name, meta }
    }
    case HIDE_DIALOG_ACTION: {
      return { name: null, meta: null }
      // return DEFAULT
    }
    default:
      return state
  }
}
