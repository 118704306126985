import React from 'react'
import styled from 'styled-components'
import { UNKNOWN_USER_ICON, MailIcon, PhoneIcon, ChatIcon } from '../icons/index.jsx'

const UserWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
`

const UserLeft = styled.div`
  display: flex;
  align-items: center;
`

const UserAvatar = styled.img`
  border-radius: 50%;
  width: 35px;
  height: 35px;
`

const UserName = styled.div`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  margin-left: 8px;
  white-space: nowrap;
  font-family: Arial;
  font-size: 13px;
  color: #05051f;
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
`

const UserRight = styled.div`
  display: flex;
  align-items: center;
`

const IconWrapper = styled.div`
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin-left: 3px;
  margin-right: 3px;
`

const UserDetails = ({
  name,
  mail,
  photo
}) => {
  return (
    <UserWrapper>
      <UserLeft>
        <UserAvatar src={photo || UNKNOWN_USER_ICON} />
        <UserName>{name}</UserName>
      </UserLeft>
      {
        mail && (
          <UserRight>
            <IconWrapper>
              <MailIcon onClick={() => window.open(`mailto:${mail}`, '_self')} />
            </IconWrapper>
            <IconWrapper>
              <PhoneIcon onClick={() => window.open(`https://teams.microsoft.com/l/call/0/0?users=${mail}`, '_blank')} />
            </IconWrapper>
            <IconWrapper>
              <ChatIcon onClick={() => window.open(`https://teams.microsoft.com/l/chat/0/0?users=${mail}`, '_blank')} />
            </IconWrapper>
          </UserRight>
        )
      }
    </UserWrapper>
  )
}

export default UserDetails
