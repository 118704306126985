import React, { useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { CHANNEL_ICON, LockedIcon, InfoIcon } from '../icons/index.jsx'
import ChannelFiles from './ChannelFiles.jsx'
import redux from '../redux/index.js'
import { LIGHT_BLUE, BLUE_BORDER, GREY_BORDER } from '../styles.js'
import { sortOn } from '../util.js'

const ChannelListWrapper = styled.div`
  height: 100%;
`

const ChannelItemWrapper = styled.div`
  cursor: pointer;
`

const ChannelItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 8px;
  background-color: ${({ selected }) => selected ? `${LIGHT_BLUE}` : 'white'};
  border-top: ${({ selected }) => selected ? `${BLUE_BORDER}` : `${GREY_BORDER}`};
  border-bottom: ${({ selected }) => selected ? `${BLUE_BORDER}` : `${GREY_BORDER}`};
  box-shadow: ${({ isExpanded }) => isExpanded ? '0 4px 4px 0 rgba(0, 0, 0, 0.25)' : 'none'};
  border-radius: ${({ isExpanded, isLast }) => !isExpanded && isLast ? '0 0 10px 10px' : '0'};
`

const ChannelItemLeft = styled.div`
  display: flex;
  align-items: center;
`

const ChannelItemRight = styled.div`
  display: flex;
  align-items: center;
`

const ChannelAvatar = styled.img`
  display: inline-block;
  width: 24px;
  height: 24px;
`

const ChannelName = styled.div`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Arial;
  font-size: 14px;
  color: #05051f;
  max-width: 200px;
`

const ChannelList = ({
  // parent parameters
  teamId,
  // redux state
  allChannels,
  selectContext,
  // redux methods
  getChannelFilesFolder,
  showChannelDetailsDialog,
  setSelectContext
}) => {
  const teamChannels = allChannels[teamId] || []
  sortOn(teamChannels, 'displayName')
  const [expanded, setExpanded] = useState([])

  const handleChannelClick = (channelId) => {
    const isExpanded = expanded.includes(channelId)
    const isSelected = channelId === selectContext?.channelId

    setSelectContext({ teamId, channelId })

    if (isExpanded && isSelected) {
      setExpanded(expanded.slice().filter(id => id !== channelId))
    } else if (!isExpanded) {
      setExpanded(expanded.concat(channelId))
      // TODO we might want to skip this later and subscribe to channel
      // notifications and not fetch channels on each expand
      getChannelFilesFolder(teamId, channelId)
    }
  }

  const handleChannelInfoClick = (e, channelId) => {
    e.stopPropagation()
    showChannelDetailsDialog(teamId, channelId)
  }

  console.log(`ChannelList RENDER teamId=${teamId} channels=${teamChannels.length}`)

  return (
    <ChannelListWrapper onClick={(e) => e.stopPropagation()}>
      {
        teamChannels.map((channel, i) => {
          console.log('channel', JSON.stringify(channel, null, 2))
          const isExpanded = expanded.includes(channel.id)
          const visibility = channel.membershipType === 'private' ? 'visible' : 'hidden'
          return (
            <ChannelItemWrapper
              key={channel.id}
              onClick={() => handleChannelClick(channel.id)}
            >
              <ChannelItem
                selected={channel.id === selectContext?.channelId}
                isExpanded={isExpanded}
                isLast={i === teamChannels.length - 1}
              >
                <ChannelItemLeft>
                  <ChannelAvatar src={CHANNEL_ICON} />
                  <LockedIcon style={{ visibility, position: 'relative', left: -9, top: 7 }} />
                  <ChannelName>{channel.displayName}</ChannelName>
                </ChannelItemLeft>
                <ChannelItemRight>
                  <InfoIcon onClick={(e) => handleChannelInfoClick(e, channel.id)} />
                </ChannelItemRight>
              </ChannelItem>
              {isExpanded && <ChannelFiles teamId={teamId} channelId={channel.id} />}
            </ChannelItemWrapper>
          )
        })
      }
    </ChannelListWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    allChannels: state.teams.channels,
    selectContext: state.teams.selectContext
  }
}

const mapDispatchToProps = (dispatch) => {
  const { dialog, teams } = redux.actions
  return {
    getChannelFilesFolder: (teamId, channelId) => {
      dispatch(teams.getChannelFilesFolder(teamId, channelId))
    },
    showChannelDetailsDialog: (teamId, channelId) => {
      dispatch(dialog.showChannelDetailsDialog(teamId, channelId))
    },
    setSelectContext: (context) => {
      dispatch(teams.setSelectContext(context))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChannelList)
