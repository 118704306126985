export const SHOW_DIALOG_ACTION = 'SHOW_DIALOG_ACTION'
export const HIDE_DIALOG_ACTION = 'HIDE_DIALOG_ACTION'

export function showSaveDialog () {
  return {
    type: SHOW_DIALOG_ACTION,
    name: 'save'
  }
}

export function showSettingsDialog () {
  return {
    type: SHOW_DIALOG_ACTION,
    name: 'settings'
  }
}

export function showTeamDetailsDialog (teamId) {
  return {
    type: SHOW_DIALOG_ACTION,
    name: 'team_details',
    meta: { teamId }
  }
}

export function showChannelDetailsDialog (teamId, channelId) {
  return {
    type: SHOW_DIALOG_ACTION,
    name: 'channel_details',
    meta: { teamId, channelId }
  }
}

export function hideDialog () {
  return {
    type: HIDE_DIALOG_ACTION
  }
}

export function showFolderDialog (item) {
  return {
    type: SHOW_DIALOG_ACTION,
    name: 'folders',
    meta: { item }
  }
}
