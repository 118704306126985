const APP_NAME = `crea.${process.env.NODE_ENV}`
const OWNER_FILTER_KEY = `${APP_NAME}.ownerFilter`
const LOCALE_KEY = `${APP_NAME}.locale`

const storage = {
  /**
   * Owner filter
   */
  getOwnerFilter () {
    return localStorage.getItem(OWNER_FILTER_KEY) === 'true'
  },
  putOwnerFilter (ownerFilter) {
    localStorage.setItem(OWNER_FILTER_KEY, ownerFilter.toString())
  },

  /**
   * Locale
   */
  getLocale () {
    return localStorage.getItem(LOCALE_KEY)
  },
  putLocale (locale) {
    localStorage.setItem(LOCALE_KEY, locale)
  }
}

export default storage
