import './index.css'
import React from 'react'
import { onReady, printDiagnostics } from './lib/office/index.js'
import { createRoot } from 'react-dom/client'
import redux from './redux'
import { Provider } from 'react-redux'
import ThemeProvider from './ThemeProvider.jsx'
import msal from './lib/msal.js'
import { MsalProvider } from '@azure/msal-react'
import App from './App.jsx'

onReady(() => {
  printDiagnostics()
  createRoot(document.getElementById('root')).render(
    <Provider store={redux.store}>
      <ThemeProvider>
        <MsalProvider instance={msal}>
          <App />
        </MsalProvider>
      </ThemeProvider>
    </Provider>
  )
})
