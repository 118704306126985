import React from 'react'
import { connect } from 'react-redux'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'
import WorkSpace from './workspace/index.jsx'
import { CreaSpinnerColor } from './components/CreaSpinner.jsx'
import redux from './redux/index.js'
import Feedback from './components/Feedback.jsx'
import Login from './Login.jsx'

/**
 * We're logged in if the api token is valid. If not, we try to login
 * using the idToken.
 */
const App = ({
  // redux state
  isLoading,
  error,
  // redux methods
  onResetError
}) => {
  console.log('>> RENDER App.jsx - accounts in App')

  const renderError = () => {
    const errorMessage = () => {
      return error ? `${error.title} (${error.message}, ${error.status})` : ''
    }
    return (
      <Feedback
        open={!!error}
        message={errorMessage()}
        onClose={onResetError}
      />
    )
  }

  return (
    <>
      <AuthenticatedTemplate>
        {isLoading && <CreaSpinnerColor />}
        {renderError()}
        <WorkSpace />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.teams.isLoading,
    error: state.error
  }
}

const mapDispatchToProps = (dispatch) => {
  const { error } = redux.actions
  return {
    onResetError: () => {
      dispatch(error.resetError())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
