const GRAPH_BASE = 'https://graph.microsoft.com/v1.0'

// TODO I got a '503 service unavailable' error when calling getDriveRootFolder() below
// so we need to have some retry logic here also

/**
 * Generates an Error() and throws it based on in which function
 * it happened and the result data from the error response.
 */
async function createError (message, res) {
  const err = new Error(message)
  err.status = res.status
  err.statusText = res.statusText
  err.url = res.url

  try {
    err.data = (await res.json())?.error
  } catch (e) {
    console.warn('failed to get error response data', e)
    err.data = ''
  }

  return err
}

/**
 * Get all teams for a user
 * https://learn.microsoft.com/en-us/graph/api/user-list-joinedteams
 */
export async function getMyTeams (token) {
  const url = `${GRAPH_BASE}/me/joinedTeams`
  const res = await fetch(url, getRequestOptions(token))
  if (res.ok) {
    return (await res.json()).value
  } else {
    throw await createError('getMyTeams()', res)
  }
}

/**
 * Get all teams for a user
 * https://learn.microsoft.com/en-us/graph/api/user-list-joinedteams
 */
export async function getTeam (teamId, token) {
  const url = `${GRAPH_BASE}/teams/${teamId}`
  const res = await fetch(url, getRequestOptions(token))
  if (res.ok) {
    return await res.json()
  } else {
    throw await createError('getTeam()', res)
  }
}

/**
 * Get photo for the current user
 */
export async function getMyPhoto (token) {
  const url = `${GRAPH_BASE}/me/photo/$value?size=64x64`
  const res = await fetch(url, getRequestOptions(token))
  if (res.ok) {
    return resToDataUrl(res)
  } else {
    throw await createError('getMyPhoto()', res)
  }
}

/**
 * Get photo for a user
 */
export async function getUserPhoto (userId, token) {
  const url = `${GRAPH_BASE}/users/${userId}/photo/$value?size=64x64`
  const res = await fetch(url, getRequestOptions(token))
  if (res.ok) {
    return resToDataUrl(res)
  } else {
    throw await createError('getUserPhoto()', res)
  }
}

/**
 * Get team photo
 */
export async function getTeamPhoto (teamId, token) {
  const url = `${GRAPH_BASE}/teams/${teamId}/photo/$value?size=64x64`
  const res = await fetch(url, getRequestOptions(token))
  if (res.ok) {
    return resToDataUrl(res)
  } else {
    throw await createError('getTeamPhoto()', res)
  }
}

/**
 * Helper function to convert a response blob to a data url
 */
async function resToDataUrl (res) {
  const b = await res.blob()
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.onloadend = function () {
      resolve(reader.result)
    }
    reader.readAsDataURL(b)
  })
}

/**
 * Get group owners
 * https://learn.microsoft.com/en-us/graph/api/group-list-owners
 */
export async function getGroupOwners (teamId, token) {
  const url = `${GRAPH_BASE}/groups/${teamId}/owners`
  const res = await fetch(url, getRequestOptions(token))
  if (res.ok) {
    return (await res.json()).value
  } else {
    throw await createError('getGroupOwners()', res)
  }
}

/**
 * Get group members
 * https://learn.microsoft.com/en-us/graph/api/group-list-members
 */
export async function getGroupMembers (teamId, token) {
  const url = `${GRAPH_BASE}/groups/${teamId}/members`
  const res = await fetch(url, getRequestOptions(token))
  if (res.ok) {
    return (await res.json()).value
  } else {
    throw await createError('getGroupMembers()', res)
  }
}

/**
 * Get all channels for a team
 */
export async function getTeamChannels (teamId, token) {
  const url = `${GRAPH_BASE}/teams/${teamId}/channels?$select=id,createdDateTime,displayName,description,isFavoriteByDefault,tenantId,webUrl,membershipType,isArchived`
  const res = await fetch(url, getRequestOptions(token))
  if (res.ok) {
    return (await res.json()).value
  } else {
    throw await createError('getTeamChannels()', res)
  }
}

/**
 * Get channel members
 * https://learn.microsoft.com/en-us/graph/api/channel-list-members
 */
export async function getChannelMembers (teamId, channelId, token) {
  const url = `${GRAPH_BASE}/teams/${teamId}/channels/${channelId}/members`
  const res = await fetch(url, getRequestOptions(token))
  if (res.ok) {
    return (await res.json()).value
  } else {
    throw await createError('getChannelMembers()', res)
  }
}

/**
 * Get the files root folder for a team and channel.
 * Needed to get the folderId and driveId for further operations.
 */
export async function getChannelFilesRootFolder (teamId, channelId, token) {
  const url = `${GRAPH_BASE}/teams/${teamId}/channels/${channelId}/filesFolder`
  const res = await fetch(url, getRequestOptions(token))
  if (res.ok) {
    return res.json()
  } else {
    throw await createError('getChannelFilesRootFolder()', res)
  }
}

/**
 * Get OneDrive root folder for a user
 */
export async function getOneDriveRoot (token) {
  const url = `${GRAPH_BASE}/me/drive/root`
  const res = await fetch(url, getRequestOptions(token))
  if (res.ok) {
    return res.json()
  } else {
    throw await createError('getOneDriveRoot()', res)
  }
}

/**
 * Get all DriveItem children of a folder
 */
export async function getFolderItems (driveId, folderId, token) {
  const url = `${GRAPH_BASE}/drives/${driveId}/items/${folderId}/children?$select=id,createdDatetime,lastModifiedDateTime,name,size,webUrl,webDavUrl,createdBy,lastModifiedBy,decorator,folder,file,image,sharepointIds&$expand=listItem`
  const res = await fetch(url, getRequestOptions(token))
  if (res.ok) {
    return (await res.json()).value
  } else {
    throw await createError('getFolderItems()', res)
  }
}

/**
 * Write a DriveItem to a parent folder
 * https://learn.microsoft.com/en-us/graph/api/driveitem-put-content
 *
 * TODO this only supports file size of 250Mb, see
 * https://learn.microsoft.com/en-us/graph/api/driveitem-createuploadsession
 */
export async function writeDriveItem ({
  driveId,
  folderId,
  fileName,
  content,
  token
}) {
  const opts = getRequestOptions(token)
  opts.method = 'PUT'
  opts.body = content

  // TODO url encode fileName!!
  const url = `${GRAPH_BASE}/drives/${driveId}/items/${folderId}:/${fileName}:/content`

  const res = await fetch(url, opts)
  if (res.ok) {
    return res.json()
  } else {
    throw await createError('writeDriveItem()', res)
  }
}

/**
 * Send a chat message to a channel.
 * This is a low level function. See the link below for how to specify
 * the data parameter.
 * https://learn.microsoft.com/en-us/graph/api/chatmessage-post
 */
export async function sendChatMessage (teamId, channelId, data, token) {
  const url = `${GRAPH_BASE}/teams/${teamId}/channels/${channelId}/messages`
  const opts = getRequestOptions(token)
  opts.headers['Content-type'] = 'application/json'
  opts.method = 'POST'
  opts.body = JSON.stringify(data)

  const res = await fetch(url, opts)
  if (res.ok) {
    return res.json()
  } else {
    throw await createError('sendChatMessage()', res)
  }
}

function getRequestOptions (token) {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
}
