import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import redux from '../redux/index.js'
import { openDriveItem } from '../util.js'
import { NavigateBefore } from '@mui/icons-material'
import { getFileIcon, getFolderIcon } from '../icons/file-icons.js'
import { BLUE, LIGHT_BLUE } from '../styles.js'

const FilesWrapper = styled.div`
  height: 100%;
  margin-bottom: 8px;
`

const ToolbarWrapper = styled.div`
  cursor: default;
  background: #ddd;
  height: 26px;
  min-height: 26px;
  display: flex;
  align-items: center;
  padding-left: 5px;
`

const Button = styled.div`
  width: 20px;
  height: 20px;
  color: ${({ disabled }) => disabled ? '#888' : '#000'};
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  &:hover {
    color: ${({ disabled }) => disabled ? '#888' : `${BLUE}`};
  }
`

const StackWrapper = styled.div`
  height: 20px;
  font-size: 12px;
  margin-left: 5px;
  color: #616161;
  display: flex;
  align-items: center;
`

const ListWrapper = styled.div`
  overflow: auto;
  height: 100%;
`

const ListItemWrapper = styled.div`
  display: flex;
  cursor: pointer;
  padding: 4px;
  background-color: ${({ selected }) => selected ? `${LIGHT_BLUE}` : 'white'};
  &:hover {
    background-color: ${({ selected }) => selected ? `${LIGHT_BLUE}` : '#ddd'};
  }
`

const IconHolder = styled.img`
  width: 20px;
  height: 20px;
`

const FileNameWrapper = styled.div`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Arial;
  font-size: 12px;
  margin-left: 4px;
  height: 20px;
  display: flex;
  align-items: center;
`

const getFileItemIcon = ({ name, folder, decorator, file }) => {
  if (folder) {
    return getFolderIcon(decorator?.iconColor)
  } else if (file) {
    return getFileIcon(name, file?.mimeType)
  }
}

const OneDriveFiles = ({
  // redux state,
  strings,
  pathStack,
  folders,
  folderItems,
  selectedFile,
  // redux methods
  setSelectedFile,
  fetchOneDriveSubFolderItems,
  setOneDrivePathStack
}) => {
  const folderId = (pathStack.length ? pathStack[pathStack.length - 1] : null)
  const fileItems = (folderId && folderItems[folderId])

  const onItemClick = (event, item) => {
    if (item.folder) {
      setOneDrivePathStack(pathStack.concat(item.id))
      fetchOneDriveSubFolderItems(item)
    } else if (item.file) {
      if (event?.detail === 1) {
        setSelectedFile(item)
      } else if (event?.detail === 2) {
        openDriveItem(item)
      }
    }
  }

  const onChangeDirectoryUp = () => {
    if (pathStack.length > 1) {
      const copy = pathStack.slice()
      copy.pop()
      setOneDrivePathStack(copy)
    }
  }

  const pathNames = pathStack.slice(1).map(fid => {
    return folders[fid]?.name || ''
  }).join(' / ')

  return (
    <FilesWrapper onClick={(e) => e.stopPropagation()}>
      <ToolbarWrapper>
        <Button
          onClick={onChangeDirectoryUp}
          disabled={pathStack.length <= 1}
        >
          <NavigateBefore style={{ width: 20, height: 20 }} />
        </Button>
        <StackWrapper>{pathNames}</StackWrapper>
      </ToolbarWrapper>
      <ListWrapper>
        {
          (fileItems || []).map(item => {
            return (
              <ListItemWrapper
                key={item.id}
                onClick={(e) => onItemClick(e, item)}
                selected={selectedFile?.id === item.id}
              >
                <IconHolder src={getFileItemIcon(item)} />
                <FileNameWrapper>{item.name}</FileNameWrapper>
              </ListItemWrapper>
            )
          })
        }
      </ListWrapper>
    </FilesWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    strings: state.locale.strings,
    pathStack: state.teams.oneDriveStack,
    folders: state.teams.folders,
    folderItems: state.teams.folderItems,
    selectedFile: state.teams.selectedFile
  }
}

const mapDispatchToProps = (dispatch) => {
  const { teams } = redux.actions
  return {
    fetchOneDriveSubFolderItems: (folder) => {
      dispatch(teams.fetchOneDriveSubFolderItems(folder))
    },
    setOneDrivePathStack: (stack) => {
      dispatch(teams.setOneDrivePathStack(stack))
    },
    setSelectedFile: (selectedFile) => {
      dispatch(teams.setSelectedFile(selectedFile))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OneDriveFiles)
