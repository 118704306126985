import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  margin-top: 14px;
`

const Title = styled.div`
  margin-left: 10px;
  margin-bottom: 4px;
  font-size: 10px;
  height: 14px;
  color: #05051f;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
`

const Hr = styled.div`
  height: 1px;
  width: 100%;
  margin-bottom: 10px;
  background-color: #d7e0e7;
`

const Separator = ({ title }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Hr />
    </Wrapper>
  )
}

export default Separator
