import store from '../store.js'

export const ERROR_REPORT_ERROR_ACTION = 'ERROR_REPORT_ERROR_ACTION'
export const ERROR_RESET_ERROR_ACTION = 'ERROR_RESET_ERROR_ACTION'

export function reportError (errorId, err) {
  window._CREAI_LAST_ERROR = err

  const state = store.getState()
  const { strings } = state.locale

  const error = {
    // Operation specific id for presenting an error title
    title: strings[errorId],
    // Comes directly from an error
    message: err.message || '',
    // If this exists, we have gotten it from a fetch() response
    status: err.status || '',
    // If this exists, we have gotten it from a fetch() response
    statusText: err.statusText || '',
    // If this exists, we have gotten it from a fetch() response
    url: err.url || '',
    // If this exists, we have gotten it from a fetch() response
    data: err.data || {}
  }

  console.info('reportError() - error state', JSON.stringify(error, null, 2))

  return {
    type: ERROR_REPORT_ERROR_ACTION,
    error
  }
}

export function resetError () {
  return {
    type: ERROR_RESET_ERROR_ACTION
  }
}
