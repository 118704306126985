import * as MSAL from '@azure/msal-browser'
import config, { scopes } from '../config.js'
const { InteractionRequiredAuthError } = MSAL

const msal = new MSAL.PublicClientApplication(config.msal)

/**
 * Fetch an access token for Graph API (outlook only)
 */
export async function getGraphAccessToken () {
  const accounts = msal.getAllAccounts()
  const request = {
    scopes,
    account: accounts[0]
  }
  try {
    const result = await msal.acquireTokenSilent(request)
    return result.accessToken
  } catch (err) {
    if (err instanceof InteractionRequiredAuthError) {
      console.info('InteractionRequiredAuthError -> acquiring via redirect method')
      msal.acquireTokenRedirect(request)
    }
    console.error(err)
    throw new Error('E_MSAL_ACCESS_TOKEN_FAILED')
  }
}

/**
 * Return the user id of the logged in user
 */
export function getUserId () {
  const accounts = msal.getAllAccounts()
  if (Array.isArray(accounts) && accounts.length > 0) {
    return accounts[0].localAccountId
  }
}

export default msal
