import { CHANGE_LOCALE_ACTION } from './actions'
import storage from '../storage'
import resources from '../../resources'

const FALLBACK_LOCALE = 'en'
const BROWSER_LOCALE = navigator.language.split('-')[0]

export function locale () {
  const INITIAL_LOCALE = storage.getLocale() || BROWSER_LOCALE

  const getStrings = (locale) => {
    let strings = resources[locale]
    if (strings) {
      console.info(`Using locale '${locale}'`)
    } else {
      console.error(`Invalid locale ${locale}, defaulting to ${FALLBACK_LOCALE}`)
      strings = resources[locale = FALLBACK_LOCALE]
    }
    return { locale, strings }
  }

  /**
   * Reducer for language resources
   */
  return function (state = getStrings(INITIAL_LOCALE), action) { // eslint-disable-line
    switch (action.type) {
      case CHANGE_LOCALE_ACTION:
        return getStrings(action.locale)
      default:
        return state
    }
  }
}
