import React from 'react'
import { useMsal } from '@azure/msal-react'
import { connect } from 'react-redux'
import { Button, List, ListItem, ListItemText } from '@mui/material'
import Dialog from './Dialog.jsx'
import { inIframe } from '../util.js'
import Separator from '../components/Separator.jsx'
import { LanguageIcon } from '../icons/index.jsx'
import redux from '../redux/index.js'

// TODO use styled components and get rid of all the style objects below

const SettingsDialog = ({
  // redux state
  strings,
  locale,
  // redux methods
  onHideSettingsDialog,
  onChangeLocale
}) => {
  const { instance, accounts } = useMsal()
  const account = accounts[0] || { name: '', username: '' }

  const handleLogout = () => instance.logoutRedirect()

  return (
    <Dialog title={strings.settings}>
      <Separator title={strings.user_info} />
      <List>
        <ListItem style={listItemStyle}>
          <ListItemText primary={strings.user_name} secondary={account.name} style={itemTextStyle} />
        </ListItem>
        <ListItem>
          <ListItemText primary={strings.email} secondary={account.username} style={itemTextStyle} />
        </ListItem>
        {!inIframe() && <Button onClick={() => handleLogout()} style={logoutButtonStyle}>{strings.logout}</Button>}
      </List>
      <Separator title={strings.locale} />
      <List>
        <ListItem
          button
          onClick={() => onChangeLocale('en')}
          selected={locale === 'en'}
        >
          <LanguageIcon />
          <ListItemText primary='English' style={itemTextStyle} />
        </ListItem>
        <ListItem
          button
          onClick={() => onChangeLocale('sv')}
          selected={locale === 'sv'}
        >
          <LanguageIcon />
          <ListItemText primary='Svenska' style={itemTextStyle} />
        </ListItem>
      </List>
    </Dialog>
  )
}

const listItemStyle = {
  paddingTop: '2px',
  paddingBottom: '2px'
}

const itemTextStyle = {
  marginLeft: '10px',
  marginTop: '0px',
  marginBottom: '2px'
}

const logoutButtonStyle = {
  background: '#394b55',
  color: 'white',
  textTransform: 'none',
  marginTop: '5px',
  marginLeft: '25px'
}

const mapStateToProps = (state) => {
  return {
    strings: state.locale.strings,
    locale: state.locale.locale
  }
}

const mapDispatchToProps = (dispatch) => {
  const { dialog, locale } = redux.actions
  return {
    onHideSettingsDialog: () => {
      dispatch(dialog.hideDialog())
    },
    onChangeLocale: (l) => {
      dispatch(locale.changeLocale(l))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsDialog)
