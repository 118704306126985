import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import ChannelList from './ChannelList.jsx'
import { UNKNOWN_TEAMS_ICON, InfoIcon, LockedIcon } from '../icons/index.jsx'
import { BLUE_BORDER, GREY_BORDER } from '../styles.js'
import redux from '../redux/index.js'
import { getUserId } from '../lib/msal.js'
import { sortOn } from '../util.js'

/**
 * TeamsList component
 */

const TeamsListWrapper = styled.div`
  padding: 8px;
  background: white;
`

const TeamsItemWrapper = styled.div`
  cursor: pointer;
  border: ${({ selected }) => selected ? `${BLUE_BORDER}` : `${GREY_BORDER}`};
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  margin-bottom: 3px;
  display: flex;
  flex-direction: column;
`

const TeamsItem = styled.div`
  padding: 8px;
  display: flex;
  justify-content: space-between;
`

const TeamsItemLeft = styled.div`
  display: flex;
  align-items: center;
`

const TeamsItemRight = styled.div`
  display: flex;
  align-items: center;
`

const TeamsAvatar = styled.img`
  display: inline-block;
  border-radius: 4px;
  background-color: white;
  width: 35px;
  height: 35px;
`

const TeamsName = styled.div`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  margin-left: -8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Arial;
  font-size: 14px;
  color: #05051f;
  max-width: 200px;
`

const TeamsList = ({
  // parent props
  expanded,
  setExpanded,
  search,
  // redux state
  items,
  teamPhotos,
  strings,
  selectContext,
  // redux methods
  fetchTeamChannels,
  showTeamDetailsDialog,
  clearSelectContext
}) => {
  const handleTeamClick = (team) => {
    if (expanded.includes(team.id)) {
      setExpanded(expanded.slice().filter(id => id !== team.id))
      if (selectContext?.teamId === team.id) {
        clearSelectContext()
      }
    } else {
      console.log('team', JSON.stringify(team, null, 2))
      setExpanded(expanded.concat(team.id))
      // TODO we might want to skip this later and subscribe to channel
      // notifications and not fetch channels on each expand
      fetchTeamChannels(team.id)
    }
  }

  const handleTeamInfoClick = (e, teamId) => {
    e.stopPropagation()
    showTeamDetailsDialog(teamId)
  }

  if (search.length >= 2) {
    items = items.filter(item => {
      return item.displayName.toLowerCase().includes(search.toLowerCase())
    })
  }

  return (
    <TeamsListWrapper>
      {
        items.map(team => {
          const src = teamPhotos[team.id] || UNKNOWN_TEAMS_ICON
          const isExpanded = expanded.includes(team.id)
          const visibility = team.visibility === 'private' ? 'visible' : 'hidden'
          return (
            <TeamsItemWrapper
              key={team.id}
              onClick={() => handleTeamClick(team)}
              selected={isExpanded}
            >
              <TeamsItem>
                <TeamsItemLeft>
                  <TeamsAvatar src={src} />
                  <LockedIcon style={{ visibility, position: 'relative', left: -8, top: 14 }} />
                  <TeamsName>{team.displayName}</TeamsName>
                </TeamsItemLeft>
                <TeamsItemRight>
                  <InfoIcon onClick={(e) => handleTeamInfoClick(e, team.id)} />
                </TeamsItemRight>
              </TeamsItem>
              {isExpanded && <ChannelList teamId={team.id} />}
            </TeamsItemWrapper>
          )
        })
      }
    </TeamsListWrapper>
  )
}

const mapStateToProps = (state) => {
  const { teams, locale } = state
  const userId = getUserId()

  const { ownerFilter, teamOwners } = teams
  const items = teams.teams.map(team => {
    if (ownerFilter) {
      const owners = teamOwners[team.id]
      if (Array.isArray(owners)) {
        if (owners.find(to => to.id === userId)) {
          return team
        }
      }
      return null
    } else {
      return team
    }
  }).filter(Boolean)

  sortOn(items, 'displayName')

  return {
    items,
    teamPhotos: teams.teamPhotos,
    strings: locale.strings,
    selectContext: teams.selectContext
  }
}

const mapDispatchToProps = (dispatch) => {
  const { dialog, teams } = redux.actions
  return {
    fetchTeamChannels: (teamId) => {
      dispatch(teams.fetchTeamChannels(teamId))
    },
    showTeamDetailsDialog: (teamId) => {
      dispatch(dialog.showTeamDetailsDialog(teamId))
    },
    clearSelectContext: () => {
      dispatch(teams.clearSelectContext())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamsList)
