import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import SaveDialog from '../dialogs/SaveDialog.jsx'
import TeamDetailsDialog from '../dialogs/TeamDetailsDialog.jsx'
import ChannelDetailsDialog from '../dialogs/ChannelDetailsDialog.jsx'
import SettingsDialog from '../dialogs/SettingsDialog.jsx'
import redux from '../redux/index.js'
import Header from './Header.jsx'
import OneDrive from './OneDrive.jsx'
import TeamsList from './TeamsList.jsx'

/**
 * Pick Dialog component based on dialog state
 */
const getDialog = (dialog) => {
  switch (dialog) {
    case 'save':
      return SaveDialog
    case 'team_details':
      return TeamDetailsDialog
    case 'channel_details':
      return ChannelDetailsDialog
    case 'settings':
      return SettingsDialog
    default:
      return null
  }
}

const WorkSpaceWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fafafa;
`

const ScrollWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
`

const WorkSpace = ({
  // redux state
  dialog,
  strings,
  allTeamIds,
  // redux methods
  fetchMyTeams,
  clearSelectContext
}) => {
  useEffect(() => {
    fetchMyTeams()
  }, [])

  const Dialog = getDialog(dialog)
  const [isDriveExpanded, setDriveExpanded] = useState(false)
  const [expandedTeams, setExpandedTeams] = useState([])
  const [search, setSearch] = useState('')

  const canCollapseAll = expandedTeams.length > 0 || isDriveExpanded

  const onCollapseAll = () => {
    setDriveExpanded(false)
    setExpandedTeams([])
    clearSelectContext()
  }

  return (
    <>
      {Dialog && <Dialog />}
      <WorkSpaceWrapper>
        <Header
          canCollapseAll={canCollapseAll}
          onCollapseAll={onCollapseAll}
          search={search}
          setSearch={setSearch}
        />
        <ScrollWrapper>
          <OneDrive
            isExpanded={isDriveExpanded}
            setExpanded={setDriveExpanded}
          />
          <TeamsList
            expanded={expandedTeams}
            setExpanded={setExpandedTeams}
            search={search}
          />
        </ScrollWrapper>
      </WorkSpaceWrapper>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    dialog: state.dialog.name,
    strings: state.locale.strings,
    allTeamIds: state.teams.teams.map(team => team.id)
  }
}

const mapDispatchToProps = (dispatch) => {
  const { teams } = redux.actions
  return {
    fetchMyTeams: () => {
      dispatch(teams.fetchMyTeams())
    },
    clearSelectContext: () => {
      dispatch(teams.clearSelectContext())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkSpace)
