import React from 'react'
import { Snackbar, SnackbarContent, IconButton } from '@mui/material'
import { AlertIcon, CloseIcon } from '../icons/index.jsx'
import styled from 'styled-components'

const Content = styled.div`
  display: flex;
  align-items: center;
`

const Message = styled.div`
  margin-left: 10px;
  height: 24px;
  line-height: 24px;
`

const Feedback = ({
  // parent props
  open,
  message,
  onClose
}) => {
  // TODO We could pass the entire error object into the Feedback
  // component instead via redux and make it better. We could expand
  // for more information, have a copy button etc.

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      open={open}
    >
      <SnackbarContent
        sx={{ backgroundColor: 'red' }}
        message={
          <Content>
            <AlertIcon />
            <Message>{message}</Message>
          </Content>
        }
        action={
          <IconButton
            color='inherit'
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        }
      />
    </Snackbar>
  )
}

export default Feedback
