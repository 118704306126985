const DEFAULT = {}

/**
 * Reducer for handling login/logout.
 */
export function login () {
  return function (state = DEFAULT, action) { // eslint-disable-line
    switch (action.type) {
      default:
        return state
    }
  }
}
