import store from './store.js'
import * as dialog from './dialog/actions.js'
import * as error from './error/actions.js'
import * as locale from './locale/actions.js'
import * as login from './login/actions.js'
import * as teams from './teams/actions.js'

export default {
  store,
  actions: {
    dialog,
    error,
    locale,
    login,
    teams
  }
}
