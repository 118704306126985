import storage from '../storage'
import { sortOn } from '../../util.js'
import {
  TEAMS_IS_LOADING_ACTION,
  TEAMS_GET_ONE_DRIVE_ACTION,
  TEAMS_GET_MY_PHOTO_ACTION,
  TEAMS_SET_ONE_DRIVE_PATH_STACK_ACTION,
  TEAMS_GET_ALL_TEAMS_ACTION,
  TEAMS_GET_TEAM_ACTION,
  TEAMS_GET_TEAM_OWNERS_ACTION,
  TEAMS_GET_TEAM_MEMBERS_ACTION,
  TEAMS_TOGGLE_OWNER_FILTER_ACTION,
  TEAMS_GET_TEAM_CHANNELS_ACTION,
  TEAMS_GET_TEAM_PHOTO_ACTION,
  TEAMS_GET_USER_PHOTO_ACTION,
  TEAMS_GET_CHANNEL_MEMBERS_ACTION,
  TEAMS_GET_CHANNEL_ROOT_FOLDER_ACTION,
  TEAMS_GET_CHANNEL_FILES_ACTION,
  TEAMS_GET_CHANNEL_SUB_FOLDER_ACTION,
  TEAMS_SET_SELECT_CONTEXT_ACTION,
  TEAMS_SET_PATH_STACK_ACTION,
  TEAMS_SET_SELECTED_FILE_ACTION
} from './actions.js'

const DEFAULT = {
  // Set to false once we have loaded everything at startup
  isLoading: false,
  // Photo for the currently logged in user (for OneDrive)
  myPhoto: null,
  // OneDrive root folder
  oneDriveRoot: null,
  // OneDrive path stack
  oneDriveStack: [],
  // Array of teams. Fetched each time at startup
  teams: [],
  // Team id -> Array of owners
  teamOwners: {},
  // Team id -> Array of members
  teamMembers: {},
  // Owner filter. Filters out owned teams when true
  ownerFilter: storage.getOwnerFilter(),
  // Team id -> Team photo / avatar
  teamPhotos: {},
  // User id -> User photo / avatar
  userPhotos: {},
  // Team id -> Array of channels
  channels: {},
  // Channel id -> Array of members
  channelMembers: {},
  // Team id -> Drive
  drives: {},
  // Channel id -> Drive root folder (/General folder under root)
  roots: {},
  // Folder id -> folder
  folders: {},
  // Folder id -> items array
  folderItems: {},
  // Channel id -> Path stack array
  pathStacks: {},
  // Select context, where we are saving files
  selectContext: null,
  // Selected file in file list
  selectedFile: null
}

/**
 * Reducer for handling teams and drives
 */
export function teams (state = DEFAULT, action) { // eslint-disable-line
  switch (action.type) {
    case TEAMS_IS_LOADING_ACTION: {
      return { ...state, isLoading: action.isLoading }
    }

    case TEAMS_GET_ONE_DRIVE_ACTION: {
      const { root } = action
      return {
        ...state,
        oneDriveRoot: root,
        oneDriveStack: [root.id]
      }
    }

    case TEAMS_GET_MY_PHOTO_ACTION: {
      return { ...state, myPhoto: action.photo }
    }

    case TEAMS_SET_ONE_DRIVE_PATH_STACK_ACTION: {
      return {
        ...state,
        oneDriveStack: action.stack.slice()
      }
    }

    case TEAMS_GET_ALL_TEAMS_ACTION: {
      return { ...state, teams: action.teams }
    }

    case TEAMS_GET_TEAM_ACTION: {
      const { team } = action
      const { teams } = state
      const newTeamsList = teams.map(t => {
        return t.id === team.id ? team : t
      })
      return {
        ...state,
        teams: newTeamsList
      }
    }

    case TEAMS_GET_TEAM_OWNERS_ACTION: {
      const { teamId, items } = action
      const { teamOwners } = state
      sortOn(items, 'displayName')
      return {
        ...state,
        teamOwners: {
          ...teamOwners,
          [teamId]: items
        }
      }
    }

    case TEAMS_GET_TEAM_MEMBERS_ACTION: {
      const { teamId, items } = action
      const { teamMembers } = state
      sortOn(items, 'displayName')
      return {
        ...state,
        teamMembers: {
          ...teamMembers,
          [teamId]: items
        }
      }
    }

    case TEAMS_TOGGLE_OWNER_FILTER_ACTION: {
      const value = !state.ownerFilter
      storage.putOwnerFilter(value)
      return { ...state, ownerFilter: value }
    }

    case TEAMS_GET_CHANNEL_MEMBERS_ACTION: {
      const { channelId, items } = action
      const { channelMembers } = state
      sortOn(items, 'displayName')
      return {
        ...state,
        channelMembers: {
          ...channelMembers,
          [channelId]: items
        }
      }
    }

    case TEAMS_GET_TEAM_CHANNELS_ACTION: {
      const { teamId, items } = action
      const { channels } = state
      return {
        ...state,
        channels: {
          ...channels,
          [teamId]: items
        }
      }
    }

    case TEAMS_GET_TEAM_PHOTO_ACTION: {
      const { teamId, photo } = action
      const { teamPhotos } = state
      return {
        ...state,
        teamPhotos: {
          ...teamPhotos,
          [teamId]: photo
        }
      }
    }

    case TEAMS_GET_USER_PHOTO_ACTION: {
      const { userId, photo } = action
      const { userPhotos } = state
      return {
        ...state,
        userPhotos: {
          ...userPhotos,
          [userId]: photo
        }
      }
    }

    case TEAMS_GET_CHANNEL_ROOT_FOLDER_ACTION: {
      const { channelId, root } = action
      const { roots } = state
      return {
        ...state,
        roots: {
          ...roots,
          [channelId]: root
        }
      }
    }

    case TEAMS_GET_CHANNEL_FILES_ACTION: {
      const { channelId, root, items } = action
      const { roots, folders, folderItems } = state
      items.sort(sortFiles)

      const foldersUpdate = { [root.id]: root }
      const subFolders = items.filter(item => !!item.folder)
      for (const subFolder of subFolders) {
        foldersUpdate[subFolder.id] = subFolder
      }

      return {
        ...state,
        roots: {
          ...roots,
          [channelId]: root
        },
        folders: {
          ...folders,
          ...foldersUpdate
        },
        folderItems: {
          ...folderItems,
          [root.id]: items
        }
      }
    }

    case TEAMS_GET_CHANNEL_SUB_FOLDER_ACTION: {
      const { folderId, items } = action
      const { folders, folderItems } = state
      items.sort(sortFiles)

      const foldersUpdate = {}
      const subFolders = items.filter(item => !!item.folder)
      for (const subFolder of subFolders) {
        foldersUpdate[subFolder.id] = subFolder
      }

      return {
        ...state,
        folders: {
          ...folders,
          ...foldersUpdate
        },
        folderItems: {
          ...folderItems,
          [folderId]: items
        }
      }
    }

    case TEAMS_SET_SELECT_CONTEXT_ACTION: {
      const { selectContext } = action
      return { ...state, selectContext }
    }

    case TEAMS_SET_PATH_STACK_ACTION: {
      const { channelId, stack } = action
      const { pathStacks } = state
      return {
        ...state,
        pathStacks: {
          ...pathStacks,
          [channelId]: stack.slice()
        }
      }
    }

    case TEAMS_SET_SELECTED_FILE_ACTION: {
      const { selectedFile } = action
      return { ...state, selectedFile }
    }

    default:
      return state
  }
}

/**
 * Predicate function for sorting files and folders
 */
function sortFiles (lhs, rhs) {
  const l = lhs.name.toLowerCase()
  const r = rhs.name.toLowerCase()
  if (lhs.folder && rhs.file) return -1
  else if (lhs.file && rhs.folder) return 1
  else if (l < r) return -1
  else if (l > r) return 1
  else return 0
}
