import { getFileExtension } from '../../util.js'

// TODO, add the following requirements to the manifest (?)
// - CompressedFile
// - File
// - CustomXmlParts

/**
 * Main onReady function for Office.
 */
export function onReady (fn) {
  Office.onReady(() => {
    console.info('Office is ready!')
    fn()
  })
}

/**
 * Print Office diagnostics.
 */
export function printDiagnostics () {
  console.info('User agent:', navigator.userAgent)
  if (isOffice()) {
    const { diagnostics } = Office.context
    if (diagnostics) {
      console.info('Office host:', diagnostics.host)
      console.info('Office platform:', diagnostics.platform)
      console.info('Office version:', diagnostics.version)
    }
  } else {
    console.info('Running in the browser and not as an add-in!')
  }
}

/**
 * Returns a file reader
 */
export function createFileReader () {
  if (isOffice()) {
    return new OfficeFileReader()
  } else {
    return new TestFileReader()
  }
}

/**
 * File reader class for office
 */
class OfficeFileReader {
  async read (pdf = false) {
    const sliceData = []
    const format = pdf ? Office.FileType.Pdf : Office.FileType.Compressed

    const getSlice = (file, sliceIndex, cb) => {
      file.getSliceAsync(sliceIndex, (result) => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          const slice = result.value
          sliceData.push(Uint8Array.from(slice.data))
          if (sliceData.length === file.sliceCount) {
            cb()
          } else {
            getSlice(file, ++sliceIndex, cb)
          }
        } else {
          cb(result.error)
        }
      })
    }

    const concatSlices = () => {
      let totalSize = 0
      for (let i = 0; i < sliceData.length; i++) {
        totalSize += sliceData[i].length
      }

      const data = new Uint8Array(totalSize)
      let index = 0

      sliceData.forEach((d) => {
        data.set(d, index)
        index += d.length
      })

      return data
    }

    return new Promise((resolve, reject) => {
      Office.context.document.getFileAsync(format, (result) => {
        const onDone = (file) => {
          return (err) => {
            file.closeAsync()
            if (err) {
              reject(err)
            } else {
              resolve(concatSlices())
            }
          }
        }

        if (result.status === Office.AsyncResultStatus.Succeeded) {
          const file = result.value
          getSlice(file, 0, onDone(file))
        } else {
          reject(result.error)
        }
      })
    })
  }
}

/**
 * File reader class for test
 */
class TestFileReader {
  async read () {
    return stringToUintArray('This is a test file')
  }
}

/**
 * If host extension is the same as the file extension, do nothing.
 * Otherwise append host extension to current file name.
 */
export function computeFileName (fileName) {
  const hostExtension = getFileExtensionFromHost()
  if (hostExtension) {
    if (getFileExtension(fileName) === hostExtension) {
      return fileName
    } else {
      return `${fileName}.${hostExtension}`
    }
  } else {
    return fileName
  }
}

/**
 * Return file extension depending on the office context.
 */
function getFileExtensionFromHost () {
  if (isWord()) {
    return 'docx'
  } else if (isExcel()) {
    return 'xlsx'
  } else if (isPowerPoint()) {
    return 'pptx'
  } else {
    return 'txt'
  }
}

/**
 * Return true if we are in Office, e.g. Word, Excel or
 * PowerPoint.
 */
export function isOffice () {
  return isWord() || isExcel() || isPowerPoint()
}

/**
 * Return true if we are running inside Word
 */
function isWord () {
  return Office.context.host === Office.HostType.Word
}

/**
 * Return true if we are running inside Excel
 */
function isExcel () {
  return Office.context.host === Office.HostType.Excel
}

/**
 * Return true if we are running inside PowerPoint
 */
function isPowerPoint () {
  return Office.context.host === Office.HostType.PowerPoint
}

/**
 * Convert a string to a Uint8Array.
 */
function stringToUintArray (string) {
  const array = new Array(string.length)
  const chars = string.split('')
  chars.forEach((c, i) => {
    array[i] = c.charCodeAt(0)
  })
  return Uint8Array.from(array)
}
