import React from 'react'
import { CloseIcon } from '../icons/index.jsx'
import styled from 'styled-components'
import { connect } from 'react-redux'
import redux from '../redux/index.js'
import { BLUE } from '../styles.js'

const DialogWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 1;
  background: rgba(255,255,255,0.7);
`

const DialogContent = styled.div`
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  background: #fafafa;
  height: 100%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
`

const DialogTitle = styled.div`
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 8px;
  background: #888;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px 3px 0 0;
`

const DialogTitleText = styled.div`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
`

const DialogTitleIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  &:hover {
    color: ${BLUE};
  }
`

const DialogBody = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
`

const Dialog = ({
  // parent props
  title,
  children,
  // redux state
  strings,
  // redux methods
  onHideDialog
}) => {
  return (
    <DialogWrapper onClick={(e) => e.stopPropagation()}>
      <DialogContent>
        <DialogTitle>
          <DialogTitleText>
            {title}
          </DialogTitleText>
          <DialogTitleIcon onClick={() => onHideDialog()}>
            <CloseIcon />
          </DialogTitleIcon>
        </DialogTitle>
        <DialogBody>
          {children}
        </DialogBody>
      </DialogContent>
    </DialogWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    strings: state.locale.strings
  }
}

const mapDispatchToProps = (dispatch) => {
  const { dialog } = redux.actions
  return {
    onHideDialog: () => {
      dispatch(dialog.hideDialog())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dialog)
