const en = {
  channel_description: 'Channel Description',
  channel_details: 'Channel Details',
  channel_name: 'Channel Name',
  icon: 'Icon',
  locale: 'Language',
  logout: 'Logout',

  //
  // Strings for initial login window
  //
  login_text1: 'Simplifying Teams for Everyone!',
  login_text2: 'Crea! is a Microsoft add-in for better overview and document management in Teams and OneDrive, totally free to use!',
  login_text3: 'Log in with Microsoft 365',
  login_text4: 'Open and save files. Use keywords for easy indexing in Microsoft search.',
  login_text5: 'The same icons, names and folder colors as in Teams for user recognition.',
  login_text6: 'Share files, make custom messages and communicate with team members directly.',
  login_text7: 'Terms and Conditions',
  login_text8: 'Support',
  login_text9: 'Learn more about Crea!',

  members_and_guests: 'Members and Guests',
  owners: 'Owners',
  save: 'Save',
  search_team: 'Search Team',
  settings: 'Settings',
  save_pdf: 'Save PDF',
  share_in_chat: 'Share in Chat',
  share_base_message: 'I have added the following file',
  team_description: 'Description',
  team_details: 'Team Details',
  team_name: 'Team Name',
  user_info: 'User Information',

  //
  // Strings related to error handling
  //
  FETCH_CHANNEL_MEMBERS_ERROR: 'Failed to get channel members',
  FETCH_MY_TEAMS_ERROR: 'Failed to get teams list',
  FETCH_TEAM_CHANNELS_ERROR: 'Failed to get team channels',
  FETCH_TEAM_OWNERS_ERROR: 'Failed to get team owners',
  FETCH_TEAM_MEMBERS_ERROR: 'Failed to get team members',
  GET_FOLDER_ERROR: 'Failed to get files folder',
  SAVE_FILE_ERROR: 'Failed to save file'
}

const sv = {
  channel_description: 'Kanalbeskrivning',
  channel_details: 'Kanaldetaljer',
  channel_name: 'Kanalnamn',
  icon: 'Ikon',
  locale: 'Språk',
  logout: 'Logga ut',

  //
  // Strings for initial login window
  //
  login_text1: 'Förenkla Teams för alla!',
  login_text2: 'Crea! är ett Microsoft-tillägg för bättre överblick och dokumenthantering i Teams och OneDrive, helt gratis att använda!',
  login_text3: 'Logga in med Microsoft 365',
  login_text4: 'Öppna och spara filer. Använd nyckelord för enkel indexering i Microsoft-sökning.',
  login_text5: 'Samma ikoner, namn och mappfärger som i Teams för användarigenkänning.',
  login_text6: 'Dela filer, skapa anpassade meddelanden och kommunicera direkt med teammedlemmar.',
  login_text7: 'Villkor',
  login_text8: 'Support',
  login_text9: 'Lär dig mer om Crea!',

  members_and_guests: 'Medlemmar och gäster',
  owners: 'Ägare',
  owners_and_members: 'Ägare och Medlemmar',
  save: 'Spara',
  search_team: 'Sök Team',
  settings: 'Inställningar',
  save_pdf: 'Spara PDF',
  share_in_chat: 'Dela i chatten',
  share_base_message: 'Jag har lagt till följande fil',
  team_description: 'Teambeskrivning',
  team_details: 'Teamdetaljer',
  team_name: 'Teamnamn',
  user_info: 'Användarinformation',

  //
  // Strings related to error handling
  //
  FETCH_CHANNEL_MEMBERS_ERROR: 'Kunde inte hämta kanalmedlemmar',
  FETCH_MY_TEAMS_ERROR: 'Kunde inte hämta teamslistan',
  FETCH_TEAM_CHANNELS_ERROR: 'Kunde inte hämta kanallistan',
  FETCH_TEAM_OWNERS_ERROR: 'Kunde inte hämta teamsägare',
  FETCH_TEAM_MEMBERS_ERROR: 'Kunde inte hämta teamsmedlemmar',
  GET_FOLDER_ERROR: 'Kunde inte hämta filkatalogen',
  SAVE_FILE_ERROR: 'Kunde inte spara filen'
}

export default {
  en,
  sv
}
