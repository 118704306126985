import store from '../store.js'
import { reportError } from '../error/actions.js'
import { hideDialog } from '../dialog/actions.js'
import { getGraphAccessToken } from '../../lib/msal.js'
import { getMyTeams, getTeam, getGroupOwners, getGroupMembers, getTeamChannels, getMyPhoto, getUserPhoto, getTeamPhoto, getChannelMembers, getChannelFilesRootFolder, sendChatMessage, getOneDriveRoot, getFolderItems, writeDriveItem } from '../../lib/graph/index.js'
import { computeFileName, createFileReader } from '../../lib/office/index.js'
import { sortOn, openDriveItem } from '../../util.js'

export const TEAMS_IS_LOADING_ACTION = 'TEAMS_IS_LOADING_ACTION'
export const TEAMS_GET_ONE_DRIVE_ACTION = 'TEAMS_GET_ONE_DRIVE_ACTION'
export const TEAMS_GET_MY_PHOTO_ACTION = 'TEAMS_GET_MY_PHOTO_ACTION'
export const TEAMS_SET_ONE_DRIVE_PATH_STACK_ACTION = 'TEAMS_SET_ONE_DRIVE_PATH_STACK_ACTION'
export const TEAMS_GET_ALL_TEAMS_ACTION = 'TEAMS_GET_ALL_TEAMS_ACTION'
export const TEAMS_GET_TEAM_ACTION = 'TEAMS_GET_TEAM_ACTION'
export const TEAMS_GET_TEAM_OWNERS_ACTION = 'TEAMS_GET_TEAM_OWNERS_ACTION'
export const TEAMS_GET_TEAM_MEMBERS_ACTION = 'TEAMS_GET_TEAM_MEMBERS_ACTION'
export const TEAMS_TOGGLE_OWNER_FILTER_ACTION = 'TEAMS_TOGGLE_OWNER_FILTER_ACTION'
export const TEAMS_GET_TEAM_CHANNELS_ACTION = 'TEAMS_GET_TEAM_CHANNELS_ACTION'
export const TEAMS_GET_TEAM_PHOTO_ACTION = 'TEAMS_GET_TEAM_PHOTO_ACTION'
export const TEAMS_GET_USER_PHOTO_ACTION = 'TEAMS_GET_USER_PHOTO_ACTION'
export const TEAMS_GET_CHANNEL_MEMBERS_ACTION = 'TEAMS_GET_CHANNEL_MEMBERS_ACTION'
export const TEAMS_GET_CHANNEL_ROOT_FOLDER_ACTION = 'TEAMS_GET_CHANNEL_ROOT_FOLDER_ACTION'
export const TEAMS_GET_CHANNEL_FILES_ACTION = 'TEAMS_GET_CHANNEL_FILES_ACTION'
export const TEAMS_GET_CHANNEL_SUB_FOLDER_ACTION = 'TEAMS_GET_CHANNEL_SUB_FOLDER_ACTION'
export const TEAMS_SET_SELECT_CONTEXT_ACTION = 'TEAMS_SET_SELECT_CONTEXT_ACTION'
export const TEAMS_SET_PATH_STACK_ACTION = 'TEAMS_SET_PATH_STACK_ACTION'
export const TEAMS_SET_SELECTED_FILE_ACTION = 'TEAMS_SET_SELECTED_FILE_ACTION'

/**
 * Get all teams
 */
export function fetchMyTeams () {
  return async function (dispatch) {
    try {
      dispatch({ type: TEAMS_IS_LOADING_ACTION, isLoading: true })
      const token = await getGraphAccessToken()

      // NOTE We use this method to get all teams for our user, but it has issues
      // with not returning all the data. Therefore we also fetch all teams separately
      // below. For more information see https://developer.microsoft.com/en-us/graph/known-issues/?search=13633
      const teams = await getMyTeams(token)
      dispatch({ type: TEAMS_GET_ALL_TEAMS_ACTION, teams })

      teams.forEach(async (team) => {
        try {
          dispatch({
            type: TEAMS_GET_TEAM_ACTION,
            team: await getTeam(team.id, token)
          })
        } catch (e) {
          console.warn(e)
        }
        try {
          dispatch({
            type: TEAMS_GET_TEAM_PHOTO_ACTION,
            teamId: team.id,
            photo: await getTeamPhoto(team.id, token)
          })
        } catch (e) {
          console.warn(e)
        }
      })

      try {
        dispatch({
          type: TEAMS_GET_MY_PHOTO_ACTION,
          photo: await getMyPhoto(token)
        })
      } catch (e) {
        console.warn(e)
      }

      dispatch({
        type: TEAMS_GET_ONE_DRIVE_ACTION,
        root: await getOneDriveRoot(token)
      })

      for (const team of teams) {
        try {
          dispatch({
            type: TEAMS_GET_TEAM_OWNERS_ACTION,
            teamId: team.id,
            items: await getGroupOwners(team.id, token)
          })
        } catch (e) {
          console.warn(e)
        }
      }

      dispatch({ type: TEAMS_IS_LOADING_ACTION, isLoading: false })
    } catch (err) {
      dispatch({ type: TEAMS_IS_LOADING_ACTION, isLoading: false })
      dispatch(reportError('FETCH_MY_TEAMS_ERROR', err))
    }
  }
}

/**
 * Get team owners
 */
export function fetchTeamOwners (teamId) {
  return async function (dispatch) {
    try {
      const token = await getGraphAccessToken()
      const items = await getGroupOwners(teamId, token)

      const state = store.getState()
      const { userPhotos } = state.teams

      items.forEach(async (item) => {
        if (!userPhotos[item.id]) {
          try {
            dispatch({
              type: TEAMS_GET_USER_PHOTO_ACTION,
              userId: item.id,
              photo: await getUserPhoto(item.id, token)
            })
          } catch (err) {
            // console.error('failed to get user photo for owner', item)
            // console.error('err.message', err.message)
            // console.error('err.status', err.status)
            // console.error('err.data', err.data)
          }
        } else {
          console.info('found cached photo for owner', item.displayName)
        }
      })

      dispatch({
        type: TEAMS_GET_TEAM_OWNERS_ACTION,
        teamId,
        items
      })
    } catch (err) {
      dispatch(reportError('FETCH_TEAM_OWNERS_ERROR', err))
    }
  }
}

/**
 * Get team members
 */
export function fetchTeamMembers (teamId) {
  return async function (dispatch) {
    try {
      const token = await getGraphAccessToken()
      const items = await getGroupMembers(teamId, token)

      const state = store.getState()
      const { userPhotos } = state.teams

      items.forEach(async (item) => {
        if (!userPhotos[item.id]) {
          try {
            dispatch({
              type: TEAMS_GET_USER_PHOTO_ACTION,
              userId: item.id,
              photo: await getUserPhoto(item.id, token)
            })
          } catch (err) {
            // console.error('failed to get user photo for member', item)
            // console.error('err.message', err.message)
            // console.error('err.status', err.status)
            // console.error('err.data', err.data)
          }
        } else {
          console.info('found cached photo for member', item.displayName)
        }
      })

      dispatch({
        type: TEAMS_GET_TEAM_MEMBERS_ACTION,
        teamId,
        items
      })
    } catch (err) {
      dispatch(reportError('FETCH_TEAM_MEMBERS_ERROR', err))
    }
  }
}

/**
 * Fetch all channels for a team
 */
export function fetchTeamChannels (teamId) {
  return async function (dispatch) {
    try {
      const state = store.getState()
      const token = await getGraphAccessToken()
      const { roots } = state.teams

      const teamChannels = await getTeamChannels(teamId, token)
      sortOn(teamChannels, 'name')

      dispatch({
        type: TEAMS_GET_TEAM_CHANNELS_ACTION,
        teamId,
        items: teamChannels
      })

      teamChannels.forEach(async (channel) => {
        if (!roots[channel.id]) {
          try {
            const root = await getChannelFilesRootFolder(teamId, channel.id, token)
            dispatch({
              type: TEAMS_GET_CHANNEL_ROOT_FOLDER_ACTION,
              channelId: channel.id,
              root
            })
          } catch (e) {
            console.error(e)
          }
        }
      })
    } catch (err) {
      dispatch(reportError('FETCH_TEAM_CHANNELS_ERROR', err))
    }
  }
}

/**
 * Get channel members
 */
export function fetchChannelMembers (teamId, channelId) {
  return async function (dispatch) {
    try {
      const token = await getGraphAccessToken()
      const items = await getChannelMembers(teamId, channelId, token)

      const state = store.getState()
      const { userPhotos } = state.teams

      items.forEach(async (item) => {
        const { userId } = item
        if (!userPhotos[userId]) {
          try {
            dispatch({
              type: TEAMS_GET_USER_PHOTO_ACTION,
              userId,
              photo: await getUserPhoto(userId, token)
            })
          } catch (err) {}
        } else {
          console.info('found cached photo for member', item.displayName)
        }
      })

      dispatch({
        type: TEAMS_GET_CHANNEL_MEMBERS_ACTION,
        channelId,
        items
      })
    } catch (err) {
      dispatch(reportError('FETCH_CHANNEL_MEMBERS_ERROR', err))
    }
  }
}

/**
 * Get channels folder
 */
export function getChannelFilesFolder (teamId, channelId) {
  return async function (dispatch) {
    try {
      const state = store.getState()
      const token = await getGraphAccessToken()

      const { roots } = state.teams
      const root = roots[channelId] || await getChannelFilesRootFolder(teamId, channelId, token)
      const { driveId } = root.parentReference

      dispatch({
        type: TEAMS_GET_CHANNEL_FILES_ACTION,
        root,
        channelId,
        items: await getFolderItems(driveId, root.id, token)
      })
    } catch (err) {
      dispatch(reportError('GET_FOLDER_ERROR', err))
    }
  }
}

/**
 * Get channels folder
 */
export function fetchTeamChannelSubFolderItems (teamId, channelId, folder) {
  return async function (dispatch) {
    try {
      const state = store.getState()
      const token = await getGraphAccessToken()

      const { roots } = state.teams
      const root = roots[channelId] || await getChannelFilesRootFolder(teamId, channelId, token)
      const { driveId } = root.parentReference

      dispatch({
        type: TEAMS_GET_CHANNEL_SUB_FOLDER_ACTION,
        folderId: folder.id,
        items: await getFolderItems(driveId, folder.id, token)
      })
    } catch (err) {
      dispatch(reportError('GET_FOLDER_ERROR', err))
    }
  }
}

/**
 * Get a OneDrive folder
 */
export function fetchOneDriveSubFolderItems (folder) {
  return async function (dispatch) {
    try {
      const state = store.getState()
      const token = await getGraphAccessToken()

      const { oneDriveRoot } = state.teams
      const { driveId } = oneDriveRoot.parentReference
      const folderId = folder ? folder.id : oneDriveRoot.id

      dispatch({
        type: TEAMS_GET_CHANNEL_SUB_FOLDER_ACTION,
        folderId,
        items: await getFolderItems(driveId, folderId, token)
      })
    } catch (err) {
      dispatch(reportError('GET_FOLDER_ERROR', err))
    }
  }
}

/**
 * Async action for saving a file to a DriveItem folder
 * fileName: file name for this file
 * shareInChat: set to true to share in channel chat, only makes sense
 * when in a teams context
 * savePdf: set to true if a copy of the document should be saved as pdf
 */
export function saveFile (fileName, shareInChat, savePdf) {
  return async function (dispatch) {
    try {
      const state = store.getState()
      const token = await getGraphAccessToken()

      const computedFileName = computeFileName(fileName)

      const {
        roots,
        pathStacks,
        selectContext,
        oneDriveRoot,
        oneDriveStack
      } = state.teams

      const {
        teamId,
        channelId,
        oneDrive
      } = selectContext

      const root = oneDrive ? oneDriveRoot : roots[channelId]
      const { driveId } = root.parentReference

      const pathStack = oneDrive ? oneDriveStack : pathStacks[channelId]
      const folderId = pathStack[pathStack.length - 1]

      const writtenFile = await writeDriveItem({
        driveId,
        folderId,
        fileName: computedFileName,
        content: await createFileReader().read(),
        token
      })

      if (savePdf) {
        setTimeout(async () => {
          console.log('saving file to pdf...')
          try {
            await writeDriveItem({
              driveId,
              folderId,
              fileName: `${computedFileName}.pdf`,
              content: await createFileReader().read(true),
              token
            })
          } catch (err) {
            console.error('failed to save document as pdf', err)
          }
        }, 10)
      }

      const folderItems = await getFolderItems(driveId, folderId, token)
      const found = folderItems.find(item => item.id === writtenFile.id)

      dispatch({
        type: TEAMS_GET_CHANNEL_SUB_FOLDER_ACTION,
        folderId,
        items: folderItems
      })

      if (found) {
        openDriveItem(found)

        if (shareInChat && teamId && channelId && !oneDrive) {
          const { strings } = state.locale
          const data = {
            body: {
              contentType: 'html',
              content: `<div><div><at id="0">@team</at>&nbsp; ${strings.share_base_message}&nbsp; <a href='${found.webUrl}'>${found.name}</a></div></div>`
            },
            mentions: [
              {
                id: 0,
                mentionText: '@team',
                mentioned: {
                  conversation: {
                    id: teamId,
                    displayName: '@team',
                    conversationIdentityType: 'team'
                  }
                }
              }
            ]
          }
          try {
            await sendChatMessage(teamId, channelId, data, token)
          } catch (e) {
            console.error(e)
          }
        }
      }

      dispatch(hideDialog())
    } catch (err) {
      dispatch(reportError('SAVE_FILE_ERROR', err))
    }
  }
}

/**
 * Sets selected team and channel context
 */
export function setSelectContext (selectContext) {
  return async function (dispatch) {
    dispatch({ type: TEAMS_SET_SELECT_CONTEXT_ACTION, selectContext })
  }
}

/**
 * Clear select context
 */
export function clearSelectContext () {
  return async function (dispatch) {
    dispatch({ type: TEAMS_SET_SELECTED_FILE_ACTION, selectedFile: null })
    dispatch({ type: TEAMS_SET_SELECT_CONTEXT_ACTION, context: null })
  }
}

/**
 * Sets path stack for a channel
 */
export function setPathStack (channelId, stack) {
  return async function (dispatch) {
    dispatch({ type: TEAMS_SET_PATH_STACK_ACTION, channelId, stack })
  }
}

/**
 * Sets path stack for OneDrive
 */
export function setOneDrivePathStack (stack) {
  return async function (dispatch) {
    dispatch({ type: TEAMS_SET_ONE_DRIVE_PATH_STACK_ACTION, stack })
  }
}

/**
 * Sets path stack for a channel
 */
export function toggleOwnerFilter () {
  return async function (dispatch) {
    dispatch({ type: TEAMS_TOGGLE_OWNER_FILTER_ACTION })
  }
}

/**
 * Sets selected file
 */
export function setSelectedFile (selectedFile) {
  return async function (dispatch) {
    dispatch({ type: TEAMS_SET_SELECTED_FILE_ACTION, selectedFile })
  }
}
