import { LogLevel } from '@azure/msal-browser'

export const scopes = [
  // Allows users to sign-in to the app, and allows the app to read the profile of signed-in users. It also allows the app to read basic company information of signed-in users
  'user.read',

  // Needed to get all information from owners and members. Otherwise all properties are
  // returned as null. See https://learn.microsoft.com/en-us/graph/permissions-overview?tabs=http#limited-information-returned-for-inaccessible-member-objects for more information
  'user.read.all',

  // Required for getTeamChannels()
  'channel.readbasic.all',

  // Required for sendChatMessage()
  'channelmessage.send',

  // Required for getChannelFilesRootfolder()
  // Required for getFolderItems()
  // Required for writeDriveItem()
  'files.readwrite.all',

  // Required for getGroupOwners() (admin consent)
  'groupmember.read.all',

  // Required for getChannelMembers() (admin consent)
  'channelmember.readwrite.all',

  // Required for getMyTeams()
  'team.readbasic.all'
]

export default {
  msal: {
    auth: {
      clientId: '8d627c4d-9985-49bb-a638-5dc2a5c8163b'
    },
    cache: {
      // TODO change to localStorage once we go live! Keeping sessionStorage for now
      // to force the login window to show up each time.
      cacheLocation: 'sessionStorage',
      // Set this to "true" if you are having issues on IE11 or Edge
      storeAuthStateInCookie: false
    },
    system: {
      allowRedirectInIframe: true,
      system: {
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
              return
            }
            switch (level) {
              case LogLevel.Error:
                console.error(message)
                return
              case LogLevel.Info:
                console.info(message)
                return
              case LogLevel.Verbose:
                console.debug(message)
                return
              case LogLevel.Warning:
                console.warn(message)
            }
          }
        }
      }
    }
  }
}
