import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import redux from '../redux/index.js'
import { openDriveItem, canOpenAssociatedDocument } from '../util.js'
import { BLUE, LIGHT_BLUE } from '../styles.js'
import { TextField, InputAdornment } from '@mui/material'
import { OwnerIcon, CollapseIcon, OpenIcon, OpenInBrowserIcon, SettingsIcon, SearchIcon, CloseIcon } from '../icons/index.jsx'

const HeaderWrapper = styled.div`
  height: 80px;
  min-height: 80px;
  background-color: #fafafa;
  border-top: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
`

const Upper = styled.div`
  display: flex;
`

const SaveButton = styled.div`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  width: 64px;
  height: 28px;

  background-color: ${({ disabled }) => disabled ? '#aaaaaa' : `${BLUE}`};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  font-size: 12px;
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  &:hover {
    color: ${({ disabled }) => disabled ? 'white' : `${LIGHT_BLUE}`};
  }
`

const IconButtonWrapper = styled.div`
  display: flex;
  height: 28px;
  align-items: center;
  margin-left: 40px;
`

const OwnerButton = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: ${({ isOn }) => isOn ? `${BLUE}` : '#000'};
  &:hover {
    color: ${BLUE};
  }
`

const Button = styled.div`
  margin-left: 12px;
  width: 20px;
  height: 20px;
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  color: ${({ disabled }) => disabled ? '#aaaaaa' : '#000'};
  &:hover {
    color: ${({ disabled }) => disabled ? '#aaaaaa' : `${BLUE}`};
  }
`

const Lower = styled.div`
  width: 100%;
`

const CloseWrapper = styled.div`
  cursor: pointer;
`

/**
 * Header component
 */
const Header = ({
  // parent props
  canCollapseAll,
  onCollapseAll,
  search,
  setSearch,
  // redux state
  strings,
  ownerFilter,
  canSave,
  selectedFile,
  // redux methods
  toggleOwnerFilter,
  openSaveDialog,
  openSettingsDialog
}) => {
  const handleOpenExternalButton = () => {
    if (selectedFile) {
      window.open(selectedFile.webUrl)
    }
  }

  const canOpenAssociated = canOpenAssociatedDocument(selectedFile?.listItem.webUrl)

  return (
    <HeaderWrapper>
      <Upper>
        <SaveButton
          disabled={!canSave}
          onClick={() => canSave && openSaveDialog()}
        >
          {strings.save}
        </SaveButton>
        <IconButtonWrapper>
          <OwnerButton
            onClick={() => toggleOwnerFilter()}
            isOn={ownerFilter}
          >
            <OwnerIcon />
          </OwnerButton>
          <Button
            onClick={() => canCollapseAll && onCollapseAll()}
            disabled={!canCollapseAll}
          >
            <CollapseIcon />
          </Button>
          <Button
            onClick={() => openDriveItem(selectedFile)}
            disabled={!canOpenAssociated}
          >
            <OpenIcon />
          </Button>
          <Button
            onClick={() => handleOpenExternalButton()}
            disabled={!selectedFile}
          >
            <OpenInBrowserIcon />
          </Button>
          <Button
            onClick={() => openSettingsDialog()}
          >
            <SettingsIcon />
          </Button>
        </IconButtonWrapper>
      </Upper>
      <Lower>
        <TextField
          margin='normal'
          variant='outlined'
          size='small'
          placeholder={strings.search_team}
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                <CloseWrapper onClick={() => setSearch('')}>
                  <CloseIcon />
                </CloseWrapper>
              </InputAdornment>
            ),
            inputProps: {
              autoCapitalize: 'off',
              autoComplete: 'off',
              autoCorrect: 'off',
              spellCheck: 'false'
            }
          }}
          value={search}
          style={{ background: 'white', marginTop: 12 }}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Lower>
    </HeaderWrapper>
  )
}

const mapStateToProps = (state) => {
  const { locale, teams } = state
  const { selectContext, selectedFile, ownerFilter } = teams

  let canSave = false
  if (selectContext) {
    const { teamId, channelId, oneDrive } = selectContext
    if (teamId && channelId) {
      const { folderItems, pathStacks } = teams
      const pathStack = pathStacks[channelId] || []
      const folderId = (pathStack.length ? pathStack[pathStack.length - 1] : null)
      canSave = !!(pathStack.length && folderId && folderItems[folderId])
    } else if (oneDrive) {
      canSave = true
    }
  }

  return {
    strings: locale.strings,
    ownerFilter,
    canSave,
    selectedFile
  }
}

const mapDispatchToProps = (dispatch) => {
  const { dialog, teams } = redux.actions
  return {
    toggleOwnerFilter: () => {
      dispatch(teams.toggleOwnerFilter())
    },
    openSaveDialog: () => {
      dispatch(dialog.showSaveDialog())
    },
    openSettingsDialog: () => {
      dispatch(dialog.showSettingsDialog())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
