import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Dialog from './Dialog.jsx'
import UserDetails from './UserDetails.jsx'
import Separator from '../components/Separator.jsx'
import redux from '../redux/index.js'
import { UNKNOWN_TEAMS_ICON } from '../icons/index.jsx'

const Text = styled.div`
  padding-left: 18px;
  padding-right: 18px;
  font-family: Arial;
  font-size: 11px;
  color: #2f2f2f;
`

const ListWrapper = styled.div`
  padding-left: 8px;
  padding-right: 8px;
`

const TeamsAvatar = styled.img`
  display: inline-block;
  border-radius: 4px;
  background-color: white;
  width: 35px;
  height: 35px;
  margin-left: 20px;
`

const TeamDetailsDialog = ({
  // redux state
  strings,
  team,
  owners,
  members,
  teamPhotos,
  userPhotos,
  // redux methods
  fetchTeamOwners,
  fetchTeamMembers,
  onHideDialog
}) => {
  useEffect(() => {
    fetchTeamOwners(team.id)
    fetchTeamMembers(team.id)
  }, [team.id])

  return (
    <Dialog title={strings.team_details}>
      <Separator title={strings.team_name} />
      <Text>{team.displayName}</Text>
      <Separator title={strings.team_description} />
      <Text>{team.description}</Text>
      <Separator title={strings.icon} />
      <TeamsAvatar src={teamPhotos[team.id] || UNKNOWN_TEAMS_ICON} />
      <Separator title={`${strings.owners} (${owners.length})`} />
      <ListWrapper>
        {
          owners.map(item => {
            return (
              <UserDetails
                key={item.id}
                name={item.displayName}
                mail={item.mail}
                photo={userPhotos[item.id]}
              />
            )
          })
        }
      </ListWrapper>
      <Separator title={`${strings.members_and_guests} (${members.length})`} />
      <ListWrapper>
        {
          members.map(item => {
            return (
              <UserDetails
                key={item.id}
                name={item.displayName}
                mail={item.mail}
                photo={userPhotos[item.id]}
              />
            )
          })
        }
      </ListWrapper>
    </Dialog>
  )
}

const mapStateToProps = (state) => {
  const { teamId } = state.dialog.meta
  const team = state.teams.teams.find(team => team.id === teamId)
  const owners = state.teams.teamOwners[teamId] || []
  const members = state.teams.teamMembers[teamId] || []
  return {
    strings: state.locale.strings,
    team,
    owners,
    members: members.filter(member => {
      return !owners.find(owner => owner.id === member.id)
    }),
    teamPhotos: state.teams.teamPhotos,
    userPhotos: state.teams.userPhotos
  }
}

const mapDispatchToProps = (dispatch) => {
  const { teams, dialog } = redux.actions
  return {
    fetchTeamOwners: (teamId) => {
      dispatch(teams.fetchTeamOwners(teamId))
    },
    fetchTeamMembers: (teamId) => {
      dispatch(teams.fetchTeamMembers(teamId))
    },
    onHideDialog: () => {
      dispatch(dialog.hideDialog())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamDetailsDialog)
