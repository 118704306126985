import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { thunk } from 'redux-thunk'

import { dialog } from './dialog/reducer.js'
import { error } from './error/reducer.js'
import { locale } from './locale/reducer.js'
import { login } from './login/reducer.js'
import { teams } from './teams/reducer.js'

const DEVTOOLS = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()

export default createStore(
  combineReducers({
    dialog,
    error,
    locale: locale(),
    login: login(),
    teams
  }),
  compose(
    applyMiddleware(thunk),
    DEVTOOLS || compose
  )
)
