import { isOffice } from './lib/office/index.js'

/**
 * Return true if we're running inside an iframe, otherwise false.
 */
export function inIframe () {
  return !(window.parent === window)
}

/**
 * Sorts a list on a property value (lower case)
 */
export function sortOn (list, property) {
  list.sort((lhs, rhs) => {
    const lName = (lhs[property] || '').toLowerCase()
    const rName = (rhs[property] || '').toLowerCase()
    if (lName < rName) return -1
    else if (lName > rName) return 1
    else return 0
  })
}

/**
 * Common file type extensions.
 */
export const WORD_FILES = ['doc', 'docx']
export const WORD_TEMPLATE_FILES = ['dot', 'dotm', 'dotx']
export const EXCEL_FILES = ['xls', 'xlsx']
export const EXCEL_TEMPLATE_FILES = ['xlt', 'xltm', 'xltx']
export const POWERPOINT_FILES = ['ppt', 'pptx']
export const POWERPOINT_TEMPLATE_FILES = ['pot', 'potm', 'potx']
export const TEMPLATE_FILES = EXCEL_TEMPLATE_FILES
  .concat(WORD_TEMPLATE_FILES)
  .concat(POWERPOINT_TEMPLATE_FILES)

/**
 * Cleans a file name from invalid characters.
 * Also tidies up multiples of spaces with a single space.
 */
export function cleanFileName (fileName) {
  let clean = fileName
    .replace(/[<>:'"/\\|?*#%]/g, '')
    .split(' ')
    .filter(Boolean)
    .join(' ')

  if (fileName.endsWith(' ')) {
    clean += ' '
  }

  return clean
}

/**
 * Return file extension from file name
 */
export function getFileExtension (name) {
  const split = name.split('.')
  return (split.length > 0 ? split[split.length - 1] : '').toLowerCase()
}

/**
 * Open a drive item.
 * Tries to open an associated file with the local client if possible
 * and falls back to opening in the browser.
 */
export function openDriveItem (item) {
  if (isOffice()) {
    const webUrl = item?.listItem.webUrl
    if (canOpenAssociatedDocument(webUrl)) {
      openAssociatedDocument(webUrl)
    } else {
      window.open(item.webUrl)
    }
  } else {
    window.open(item.webUrl)
  }
}

/**
 * Return true if webUrl is a document that can be opened in the local client
 * as an associated document on sharepoint
 */
export function canOpenAssociatedDocument (webUrl) {
  return webUrl ? !!getOfficeUriProtocol(getFileExtension(webUrl)) : false
}

/**
 * Open a document using the office uri scheme
 */
function openAssociatedDocument (webUrl) {
  const ext = getFileExtension(webUrl)
  const protocol = getOfficeUriProtocol(ext)
  if (protocol) {
    const isTemplate = isTemplateFile(ext)
    const command = isTemplate ? 'nft' : 'ofe'
    const url = `${protocol}:${command}|u|${webUrl}`
    console.info('opening document using office uri scheme', url)
    window.open(url)
  }
}

/**
 * Return office uri protocol based based on file extension.
 */
function getOfficeUriProtocol (ext) {
  if (WORD_FILES.includes(ext) ||
      WORD_TEMPLATE_FILES.includes(ext)) {
    return 'ms-word'
  } else if (POWERPOINT_FILES.includes(ext) ||
             POWERPOINT_TEMPLATE_FILES.includes(ext)) {
    return 'ms-powerpoint'
  } else if (EXCEL_FILES.includes(ext) ||
             EXCEL_TEMPLATE_FILES.includes(ext)) {
    return 'ms-excel'
  } else {
    return ''
  }
}

/**
 * Return true if extension is a template
 */
function isTemplateFile (ext) {
  return TEMPLATE_FILES.includes(ext)
}
