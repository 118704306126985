import React from 'react'
import { useMsal } from '@azure/msal-react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { UNKNOWN_USER_ICON } from '../icons/index.jsx'
import { BLUE_BORDER, GREY_BORDER } from '../styles.js'
import redux from '../redux/index.js'
import OneDriveFiles from './OneDriveFiles.jsx'

const OneDriveWrapper = styled.div`
  padding: 8px;
  border-bottom: 1px solid #c4c4c4;
  background-color: #fafafa;
`

const OneDriveItem = styled.div`
  cursor: pointer;
  border: ${({ selected }) => selected ? `${BLUE_BORDER}` : `${GREY_BORDER}`};
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  margin-bottom: 3px;
  display: flex;
  flex-direction: column;
`

const OneDriveItemHeader = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  background-color: ${({ selected }) => selected ? '#c9e6ff' : 'white'};
  border-radius: ${({ selected }) => selected ? '10px 10px 0 0' : '10px'};
`

const OneDriveAvatar = styled.img`
  display: inline-block;
  border-radius: 50%;
  background-color: white;
  width: 35px;
  height: 35px;
`

const OneDriveName = styled.div`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Arial;
  font-size: 14px;
  color: #05051f;
  max-width: 75%;
`

const OneDrive = ({
  // parent props
  isExpanded,
  setExpanded,
  // redux state
  myPhoto,
  strings,
  selectContext,
  // redux methods
  fetchOneDriveSubFolderItems,
  setSelectContext,
  clearSelectContext
}) => {
  const { accounts } = useMsal()
  const account = accounts[0] || { name: '' }

  const handleOneDriveClick = () => {
    if (isExpanded && selectContext?.oneDrive) {
      setExpanded(false)
      clearSelectContext()
    } else if (isExpanded) {
      setSelectContext({ oneDrive: true })
      fetchOneDriveSubFolderItems()
    } else {
      setExpanded(true)
      setSelectContext({ oneDrive: true })
      fetchOneDriveSubFolderItems()
    }
  }

  return (
    <OneDriveWrapper>
      <OneDriveItem
        onClick={() => handleOneDriveClick()}
        selected={isExpanded}
      >
        <OneDriveItemHeader
          selected={selectContext?.oneDrive}
        >
          <OneDriveAvatar src={myPhoto} />
          <OneDriveName>OneDrive - {account.name}</OneDriveName>
        </OneDriveItemHeader>
        {isExpanded && <OneDriveFiles />}
      </OneDriveItem>
    </OneDriveWrapper>
  )
}

const mapStateToProps = (state) => {
  const { teams, locale } = state
  return {
    strings: locale.strings,
    myPhoto: teams.myPhoto || UNKNOWN_USER_ICON,
    selectContext: teams.selectContext
  }
}

const mapDispatchToProps = (dispatch) => {
  const { teams } = redux.actions
  return {
    fetchOneDriveSubFolderItems: () => {
      dispatch(teams.fetchOneDriveSubFolderItems())
    },
    setSelectContext: (context) => {
      dispatch(teams.setSelectContext(context))
    },
    clearSelectContext: () => {
      dispatch(teams.clearSelectContext())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OneDrive)
