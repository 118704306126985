import React from 'react'
import { connect } from 'react-redux'
import { useMsal } from '@azure/msal-react'
import { scopes } from './config.js'
import { inIframe } from './util.js'
import styled from 'styled-components'
import { CreaLogoBlackIcon, ConnectedBoxesIcon, FloppyDiskIcon, TextBalloonIcon } from './icons/index.jsx'
import { CreaLogoColor, CreaLogoWhite } from './icons/CreaLogo.jsx'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`

const Upper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const Text1 = styled.div`
  font-size: 15px;
  font-weight: bold;
  margin-top: 22px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const Text2 = styled.div`
  font-size: 13px;
  color: #707070;
  max-width: 300px;
  margin-top: 22px;
  text-align: center;
`

const LoginButton = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  width: 240px;
  font-size: 13px;
  background-color: #0176d5;
  color: #ffffff;
  text-align: center;
  align-content: center;
  margin-top: 22px;
  margin-bottom: 52px;
  border-radius: 15px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const Features = styled.div`
  width: 240px;
`

const Feature = styled.div`
  display: flex;
  margin-bottom: 15px;
`

const FeatureText = styled.div`
  font-size: 11px;
  color: #707070;
  margin-left: 19px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const Lower = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const LinkText = styled.div`
  margin-left: 5px;
  color: #707070;
  font-size: 13px;
`

const LearnMore = styled.div`
  display: flex;
  height: 30px;
  width: 240px;
  background-color: #20a3a9;
  align-items: center;
  justify-content: space-between;
  padding-left: 8px;
  margin-top: 44px;
  margin-bottom: 21px;
  border-radius: 15px;
  cursor: pointer;
`

const LearnMoreText = styled.div`
  display: flex;
  width: 100%;
  color: #ffffff;
  font-size: 13px;
  justify-content: space-evenly;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d7e0e7;
`

const Copyright = styled.div`
  height: 30px;
  font-size: 11px;
  color: #636363;
  align-content: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

/**
 * Login page
 */
const Login = ({
  // redux state
  strings
}) => {
  const { instance } = useMsal()

  const handleLogin = () => {
    const login = inIframe() ? 'loginPopup' : 'loginRedirect'
    console.log(`calling msal.${login}()`)
    instance[login]({ scopes }).then((response) => {
      console.log(`msal.${login}()`, response)
    }).catch(err => {
      console.error('msal.loginRedirect() failed', err)
    })
  }

  return (
    <Wrapper onClick={(e) => e.stopPropagation()}>
      <Upper>
        <CreaLogoBlackIcon />
        <Text1>{strings.login_text1}</Text1>
        <Text2>{strings.login_text2}</Text2>
        <LoginButton onClick={() => handleLogin()}><div style={{ width: '100%' }}>{strings.login_text3}</div></LoginButton>
        <Features>
          <Feature>
            <FloppyDiskIcon />
            <FeatureText>{strings.login_text4}</FeatureText>
          </Feature>
          <Feature>
            <ConnectedBoxesIcon />
            <FeatureText>{strings.login_text5}</FeatureText>
          </Feature>
          <Feature>
            <TextBalloonIcon />
            <FeatureText>{strings.login_text6}</FeatureText>
          </Feature>
        </Features>
      </Upper>
      <Lower>
        <LinkWrapper onClick={() => window.open('https://creai.se/terms-and-conditions', '_blank')}>
          <CreaLogoColor />
          <LinkText>{strings.login_text7}</LinkText>
        </LinkWrapper>
        <LinkWrapper onClick={() => window.open('https://creai.se/support', '_blank')}>
          <CreaLogoColor />
          <LinkText>{strings.login_text8}</LinkText>
        </LinkWrapper>
        <LearnMore onClick={() => window.open('https://creai.se', '_black')}>
          <CreaLogoWhite />
          <LearnMoreText>{strings.login_text9}</LearnMoreText>
        </LearnMore>
        <Separator />
        <Copyright>© 2024 Pergas AB</Copyright>
      </Lower>
    </Wrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    strings: state.locale.strings
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
