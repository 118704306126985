import { ERROR_REPORT_ERROR_ACTION, ERROR_RESET_ERROR_ACTION } from './actions.js'

/**
 * Reducer for handling errors
 */
export function error (state = null, action) { // eslint-disable-line
  switch (action.type) {
    case ERROR_REPORT_ERROR_ACTION: {
      return { ...action.error }
    }
    case ERROR_RESET_ERROR_ACTION:
      return null
    default:
      return state
  }
}
